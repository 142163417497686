<template>
  <div class="solution">
    <div class="f1  itemA">
      <div class="title">助力企业解决营销关键点</div>
      <ul>
        <li data-aos="fade-right" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-1.png" alt="" />
          </div>
          <div class="text">
            <p>流量</p>
            <span>拓展多个高频场景，进行流量引入。</span>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-2.png" alt="" />
          </div>
          <div class="text">
            <p>渠道</p>
            <span>搭建营销场景 / 平台连接用户做深度营销。</span>
          </div>
        </li>
        <li data-aos="fade-left" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-3.png" alt="" />
          </div>
          <div class="text">
            <p>触达</p>
            <span>结合营销工具与品牌资源有节奏的触达用户。</span>
          </div>
        </li>
        <li data-aos="fade-right" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-4.png" alt="" />
          </div>
          <div class="text">
            <p>技术</p>
            <span>银行级系统安全服务，支持多种系统对接方案。</span>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-5.png" alt="" />
          </div>
          <div class="text">
            <p>产品</p>
            <span>根据不用的渠道及人群精准匹配营销产品。</span>
          </div>
        </li>
        <li data-aos="fade-left" data-aos-duration="500">
          <div class="imgWrap">
            <img src="../../assets/Solution/f1-6.png" alt="" />
          </div>
          <div class="text">
            <p>数据</p>
            <span>建设用户数据体系，实现精准推送。</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="f2 w-100vw f-common itemB">
      <div class="w-1400px">
        <div class="title" data-aos="slide-up">助力企业用户精细化营销</div>
        <div class="subTitle" data-aos="slide-up">
          通过洞察不同类型的消费群体，在策略、运营和场景等多个方面进行精准分析，<br />针对性营销细分人群和场景，把握增长营销策略。
        </div>
        <div class="main">
          <ul>
            <li data-aos="fade-right">
              <p>延展目标人群驱动营销</p>
              <span
                >瞄准行业核心人群、渗透下沉市场以及细分长尾人群(小镇青年、Z
                世代等）。</span
              >
            </li>
            <li data-aos="fade-right" data-aos-dely="200">
              <p>高频场景营销</p>
              <span
                >识别用户消费频率高的产品频率，通过挖掘细分场景需求，打造关联场景进行营销渗透，弥补金融场景客户黏性不足的弱势。</span
              >
            </li>
            <li data-aos="fade-right" data-aos-dely="400">
              <p>品牌联动营销</p>
              <span
                >联动热门品牌、附近商圈共同营销，对用户进行全方位触达。</span
              >
            </li>
          </ul>
          <img
            data-aos="fade-left"
            src="../../assets/Solution/f2-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="f3  f-common itemC">
      <div class="title" data-aos="slide-up">助力打造场景金融生态圈</div>
      <div class="subTitle" data-aos="slide-up">
        围绕用户的需求，打造高黏性的场景服务，帮助金融企业完善场景生态，<br />
        实现营销与需求的无缝连接。
      </div>
      <div class="main">
        <img
          data-aos="fade-right"
          src="../../assets/Solution/f3-1.png"
          alt=""
        />
        <ul>
          <li data-aos="fade-left">
            <p>基于各种需求的场景服务</p>
            <span>如日常生活充值、餐饮美食、超市便利、京东优选......</span>
          </li>
          <li data-aos="fade-left" data-aos-dely="200">
            <p>基于细分群体的场景服务</p>
            <span
              >如新锐白领、资深中产、精致妈妈、小镇青年、Gen Z
              (Z世代)、都市银发、小镇中老年和都市蓝领、限定区域群体......</span
            >
          </li>
          <li data-aos="fade-left" data-aos-dely="400">
            <p>基于不同渠道的场景服务</p>
            <span
              >如营业网点用户、客户经理私人用户、对公用户、信用卡用户、网金用户、个金用户.....</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="f4 w-100vw f-common itemD">
      <div class="w-1400px">
        <div class="title" data-aos="slide-up">助力企业数字化营销</div>
        <div class="subTitle" data-aos="slide-up">
          从数字化平台、数据化管理和洞察到用户消费行为精准分析，<br />
          助力企业建立从方法论到实践落地的“数据驱动增长体系”，真正实现数字化营销增长模式。
        </div>
        <div class="main">
          <ul>
            <li data-aos="fade-right">
              <p>平台数字化体系</p>
              <span
                >·立专属数字营销平台，数字化与目标客户及受众群体的触点；<br />
                ·个性化数字营销商品供应链，提供具有吸引力的营销商品，助力用户转化提升；<br />
                ·按业务目标定制营销活动方案，助力企业获客、活客。</span
              >
            </li>
            <li data-aos="fade-right" data-aos-dely="200">
              <p>用户数据的数字化管理</p>
              <span
                >·用户数据的汇总、管理、识别与合并；<br />
                ·数据的多维度分析，打造用户标签管理体系；<br />
                ·通过数据挖掘，形成精准用户画像。</span
              >
            </li>
            <li data-aos="fade-right" data-aos-dely="400">
              <p>数字化营销洞察和分析</p>
              <span
                >·洞察客户群体的状态、人群特征和行为偏好；<br />
                ·精细化分析客户购买频次、购买偏好和购买动机；<br />
                ·分析客户群体的增加与流失，掌握核心及长尾用户的状态；<br />
                ·用户成长周期分析，丰富营销手段，更好的拉新、留存和转化。</span
              >
            </li>
          </ul>
          <img
            data-aos="fade-left"
            src="../../assets/Solution/f4-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="f5  itemE">
      <img
        class="title"
        data-aos="slide-up"
        src="../../assets/Solution/f6-1.png"
        alt=""
      />
      <ul>
        <li data-aos="fade-right">
          <div class="imgWrap">
            <img src="../../assets/Solution/f6-2.png" alt="" />
          </div>
          <p>技术保障</p>
          <span>系统自动优选渠道</span>
          <span>百万级日交易触发量</span>
          <span>业务成功率99.99%</span>
          
        </li>
        <li data-aos="fade-right">
          <div class="imgWrap">
            <img src="../../assets/Solution/f6-3.png" alt="" />
          </div>
          <p>运营保障</p>
          <span>项目专人负责</span>
          <span>量化营销结果</span>
          <span>个性化营销策划</span>
        </li>
        <li data-aos="fade-left">
          <div class="imgWrap">
            <img src="../../assets/Solution/f6-4.png" alt="" />
          </div>
          <p>客服服务</p>
          <span>支持7x24小时服务</span>
          <span>专属工单处理系统</span>
          <span>大客户服务标准</span>
        </li>
        <li data-aos="fade-left">
          <div class="imgWrap">
            <img src="../../assets/Solution/f6-5.png" alt="" />
          </div>
          <p>流程管控</p>
          <span>总分式账号管控</span>
          <span>图形化数据</span>
          <span>可视化财务管理</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      active: 0, // 当前激活的导航索引
      scrollTop:'',//滚动距离
      offsetTop:[
        {itemA:""},
        {itemB:""},
        {itemC:""},
        {itemD:""},
        {itemE:""}
      ],
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll)
      this.offsetTop.itemA = document.querySelector('.itemA').offsetTop
      this.offsetTop.itemB = document.querySelector('.itemB').offsetTop
      this.offsetTop.itemC = document.querySelector('.itemC').offsetTop
      this.offsetTop.itemD = document.querySelector('.itemD').offsetTop
      this.offsetTop.itemE = document.querySelector('.itemE').offsetTop
    })

    const linkTo = this.$route.params.to;
    if(linkTo != undefined && linkTo){
      this.scrollTo(linkTo)
    }
  },
  computed:{
    SolutionTo(){
      return this.$store.state.SolutionTo
    }
  },
  watch: {
    SolutionTo(newValue, oldValue) {
      this.scrollTo(newValue)
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll)    
  },
  methods: {
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
      this.scrollTop = scrollTop//data里return了一个全局的scrollTop
      // console.log(scrollTop)
      //这边距离用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
      if (scrollTop > this.offsetTop.itemB-100) {
        this.active = 1
      }
      if(scrollTop > this.offsetTop.itemC-100){
        this.active = 2
      }
      if(scrollTop > this.offsetTop.itemD-100){
        this.active = 3
      }
      if(scrollTop > this.offsetTop.itemE-100){
        this.active = 4
      }
      if(scrollTop < this.offsetTop.itemB-100){
        this.active = 5
      }
    },
    //跳转到指定元素
      scrollTo(val){
        this.active = val
        // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
        const STEP = 100
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
        var targetOffsetTop = ''
        if(val == 1){
          targetOffsetTop = document.querySelector('.itemB').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 2){
          targetOffsetTop = document.querySelector('.itemC').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 3){
          targetOffsetTop = document.querySelector('.itemD').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 4){
          targetOffsetTop = document.querySelector('.itemE').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 5){
          targetOffsetTop = document.querySelector('.itemA').offsetTop - 60//获取指定元素视口距离
        }
        if (scrollTop < targetOffsetTop) {
          // 往下滑
          smoothDown()
          // console.log(targetOffsetTop,'视口距离')
        }else{
          // console.log(targetOffsetTop,'targetOffsetTop')
          smoothUp()
        }
        // 定义下滑函数
        function smoothDown() {
          // console.log(scrollTop,'scrollTop')
          // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
          if (scrollTop < targetOffsetTop) {
            // 如果和目标相差距离大于等于 STEP 就跳加上 STEP
            // 否则直接跳到目标点，目标是为了防止跳过了。
            if (targetOffsetTop - scrollTop >= STEP) {
              scrollTop += STEP
            } else {
              scrollTop = targetOffsetTop
            }
            document.body.scrollTop = scrollTop
            document.documentElement.scrollTop = scrollTop
            // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
            // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
            requestAnimationFrame(smoothDown)
          }
        }
        // 定义上滑函数
        function smoothUp() {
          // 如果当前 scrollTop 大于 targetOffsetTop 说明视口已经滑过指定位置需要上滑
          if (scrollTop > targetOffsetTop) {
            // 如果和目标相差距离大于等于 STEP 就跳减去 STEP
            // 否则直接跳到目标点，目标是为了防止跳过了。
            if (scrollTop - targetOffsetTop >= STEP) {
              scrollTop -= STEP
            } else {
              scrollTop = targetOffsetTop
            }
            document.body.scrollTop = scrollTop
            document.documentElement.scrollTop = scrollTop
            // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
            // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
            requestAnimationFrame(smoothUp)
          }
        }        
      },
  },
};
</script>

<style src="./Solution.scss" lang="scss" scoped></style>
